import {Controller} from '@hotwired/stimulus';

import axios from 'axios';
import Swal from 'sweetalert2';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static values = { url: String, title: String }
    showLoader(event) {
        event.preventDefault()
        Swal.fire({
            title: "Möchten Sie fortfahren?",
            text: "Bitte beachten Sie, dass dieser Vorgang unwiderruflich ist.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ja, bitte löschen"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(this.urlValue)
                    .then(response => {
                        Swal.fire({
                            title: 'Es wurde gelöscht!',
                            showConfirmButton: true,
                        }).then((result) => {
                            this.reload();
                        })
                        ;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        })
    }

    reload() {
        location.reload();
    }
}
