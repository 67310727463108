// standort_controller.js

import {Controller} from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
    static values = {
        itpartnerId: String,
        standortId: String,
        status: String
    }

    toggleStandort(event) {
        event.preventDefault();

        const status = this.statusValue;
        Swal.fire({
            title: `Sind Sie sich sicher, dass der Standort ${status} werden soll?`,
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
        }).then((result) => {
            if (result.isConfirmed) {
                const url = `/verwaltung/it-partner/${this.itpartnerIdValue}/${this.standortIdValue}`;

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        return response.json();
                    }).then(data => {
                        if (data.error) {
                            throw new Error(data.error);
                        }
                    if (data.message) {
                        Swal.fire({
                            title: 'Erfolg!',
                            text: data.message, // dynamically set the text here
                            icon: 'success',
                            timer: 2000,
                            showConfirmButton: false
                        }).then(() => {
                            window.location.reload();
                        });
                    } else {
                        throw new Error('Server error');
                    }
                })
                    .catch((error) => {
                        Swal.fire({
                            title: 'Fehler!',
                            text: error.message,
                            icon: 'error',
                            confirmButtonText: 'Schließen'
                        });
                    });
            }
        });
    }
}
