import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    static values = {
        checkTargetEmpty: Boolean
    }
    static targets = ["checkbox", "depended", "targetCheckedEmpty"]

    connect() {
        this.toggle();
        if (this.checkTargetEmptyValue) {
            if (this.hasTargetCheckedEmptyTarget) {
                if (this.targetCheckedEmptyTarget.tagName === 'SELECT') {
                    this.checkSelect();
                }
            }
        }
    }

    toggle() {
        if (this.checkboxTarget.checked) {
            this.dependedTarget.classList.remove('d-none');
        } else {
            this.dependedTarget.classList.add('d-none');
        }
    }

    checkSelect() {
        if (this.targetCheckedEmptyTarget.options.length <= 1) {
            this.checkboxTarget.disabled = true;
        }
    }
}
