import {Controller} from "stimulus";

export default class extends Controller {
    static values = {
        target: String,
        class: String,
    };

    copyToClipboard() {
        const target = document.getElementById(this.targetValue);
        const targetText = target.innerText;
        navigator.clipboard.writeText(targetText)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch((err) => {
                console.error('Unable to copy text: ', err);
            });

        const button = $('#button' + this.targetValue).parent();
        const originalTitle = button.attr('data-bs-original-title');

        const originalClass = button.className;
        button.className = "btn btn-primary";
        $(button).tooltip('dispose');
        $(button).attr('data-bs-original-title', 'Variable kopiert!').tooltip().tooltip('show');

        setTimeout(() => {
            button.className = originalClass;
            $(button).tooltip('dispose');
            $(button).attr('data-bs-original-title', originalTitle).tooltip();
        }, 1500);
    }
}
