import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import '../styles/templates/avatar-upload.scss';
export default class extends Controller {
    static values = {
        form: String
    }

    static targets = ["image"];

    connect() {
        this.avatarCopper = null;
    }
    selectImage(event) {
        const previewContainer = document.getElementById('preview-container')
        const zoomSliderContainer = document.getElementById('zoom_slider_container');
        const editModal = new bootstrap.Modal(document.getElementById('editModal'), {
            keyboard: false
        })

        const files = event.target.files;
        if (files && files.length > 0) {
            previewContainer.classList.remove('avatar-upload-preview-container-hidden');
            zoomSliderContainer.classList.remove('avatar-upload-custom-range-hidden');
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.imageTarget.src = e.target.result;
                this.imageTarget.style.display = 'block';

                if (this.avatarCopper) {
                    this.avatarCopper.destroy(); // Destroy the old cropper instance
                }
                this.initializeCropper();
            };
            reader.readAsDataURL(file);
        }
    }

    initializeCropper() {
        this.avatarCopper = new Cropper(this.imageTarget, {
            aspectRatio: 1/1,
            viewMode: 1,
            dragMode: 'move',
            autoCropArea: 1, // Defines the initial crop area.
            restore: true,
            guides: true,
            modal: true,
            center: true,
            highlight: true,
            cropBoxMovable: false,
            cropBoxResizable: false,
            zoomOnWheel: false,
        });
    }

    zoomImage(event) {
        const containerData = this.avatarCopper.getContainerData();
        if (this.avatarCopper) {
            this.avatarCopper.zoomTo(parseFloat(event.target.value), {x: containerData.width / 2, y: containerData.height / 2});
        }
    }

    formSubmit(event) {
        const form = document.querySelector('form[name="' + this.formValue + '"]');
        const input = document.querySelector('input[type="file"]');
        event.preventDefault(); // Prevent the normal form submission

        if (this.avatarCopper) {
            this.avatarCopper.getCroppedCanvas().toBlob( (blob) => {
                console.log(blob)
                const newFile = new File([blob], "cropped-image.png", {
                    type: "image/png",
                    lastModified: new Date().getTime()
                });

                // Create a new DataTransfer object and append the file
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(newFile);

                // Replace the input file's files with the new DataTransfer fileList
                input.files = dataTransfer.files;

                // Finally submit the form
                form.submit();
            }, 'image/png');
        }
    }
}
