import {Controller} from 'stimulus';
import Swal from "sweetalert2";

export default class extends Controller {
    static values = {url: String};

    deleteLogo() {
        fetch(this.urlValue, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.element.remove();
                    Swal.fire({
                        title: 'Logo wurde gelöscht',
                        showConfirmButton: true,
                    }).then(() => {
                        this.reload()
                    })
                } else {
                    console.error('Error:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    reload() {
        location.reload();
    }
}
