import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        url: String,
    };

    connect() {
        this.loadContent();
    }

    async loadContent() {
        try {
            const response = await fetch(this.urlValue);
            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }

            const content = await response.text();
            this.element.innerHTML = content;
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error) {
        console.error("Fetch error:", error);
        this.element.innerHTML =
            '<div class="alert alert-danger">Inhalt konnte nicht geladen werden, bitte versuchen Sie es später erneut.</div>';
    }
}
