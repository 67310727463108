import { Controller } from "stimulus";

export default class extends Controller {
    static values = {
        url: String,
    }

    statusChange() {

        if (!this.urlValue) {
            console.error('No URL provided');
            return;
        }

        fetch(this.urlValue, { method: 'GET' })
            .then(response => response.json())
            .then(response => {
                console.log(response);
                this.swapIcon();
                setTimeout(() => {
                    this.element.remove();
                }, 1000);
            })
            .catch(error => {
                console.error(error);
            });
    }

    swapIcon() {
        const icon = this.element.querySelector('.fa-edit');
        if (icon) {
            icon.classList.remove('fa-edit');
            icon.classList.add('fa-check');
        } else {
            console.error("Icon not found within button.");
        }
    }
}
